import { RoundIcon } from "./round-icon";
import twitter from "../assets/twitter.png";
import dexscreener from "../assets/dexscreener.png";
import etherscan from "../assets/etherscan.png";
import uniswap from "../assets/uniswap.png";
import telegram from "../assets/telegram.png";

const ClickableIcon = ({ icon, text, className, href }) => {
  return (
    <RoundIcon
      icon={icon}
      className={`bg-black ${className}`}
      text={text}
      isClickable
      href={href}
    />
  );
};

export const IconLinks = ({ className }) => {
  return (
    <div
      className={`flex flex-row flex-wrap justify-center items-center content-center gap-10 mt-12 ${className}`}
    >
      <ClickableIcon icon={twitter} text={"Twitter"} href={"https://twitter.com/Ackchyuallybot"} />
      <ClickableIcon icon={dexscreener} text={"Dexscreener"} href={"https://dexscreener.com/ethereum/0xa98f536c87bf20ba141a37e75a27ba73ae073ff5"} />
      <ClickableIcon icon={etherscan} text={"Etherscan"} href={"https://etherscan.io/token/0x8c120980fd43bea3fdf1e76e59a460bd7e16a1ca"} />
      <ClickableIcon icon={uniswap} text={"Uniswap"} href={"https://app.uniswap.org/#/swap"} />
      <ClickableIcon icon={telegram} text={"Telegram"} href={"https://t.me/+giCCRYNqQ9BhYzkx"} />
    </div>
  );
};
