import { useEffect, useRef, useState } from "react";
import { useWindowResizeListener } from "../hooks/useWindowResizeListener";

const PillButton = ({ children, className, href }) => {
  return (
    <a
      className={`text-white text-center font-verdana rounded-full w-72 px-8 py-4 font-bold text-2xl ${className}`}
      href={href}
    >
      {children}
    </a>
  );
};

export const Buttons = ({ className }) => {
  const [buttonsCenter, setButtonsCenter] = useState(0);
  const buttonsRef = useRef(null);

  const onWindowResize = () => {
    const rect = buttonsRef.current.getBoundingClientRect();
    setButtonsCenter(rect.bottom - rect.height / 2 + window.scrollY);
  };

  useEffect(() => {
    onWindowResize();
  }, []);

  useWindowResizeListener(onWindowResize);

  return (
    <>
      <div
        className={`absolute -z-20 bg-gray-primary w-full top-0 left-0`}
        style={{ height: `${buttonsCenter}px` }}
      ></div>
      <div
        ref={buttonsRef}
        className={`flex flex-col sm:flex-row justify-center items-center content-center sm:space-x-4 sm:space-y-0 space-y-4 mt-8 ${className}`}
      >
        <PillButton
          className={`bg-red-primary hover:cursor-pointer hover:scale-105`}
          href={"https://dexscreener.com/ethereum/0xa98f536c87bf20ba141a37e75a27ba73ae073ff5"}
        >
          BUY NOW
        </PillButton>
        <PillButton
          className={`bg-black hover:cursor-pointer hover:scale-105`}
          href={"https://dexscreener.com/ethereum/0xa98f536c87bf20ba141a37e75a27ba73ae073ff5"}
        >
          CHART
        </PillButton>
      </div>
    </>
  );
};
