import arrow from "../assets/arrow.svg";
import logo from "../assets/logo.png";
import { RoundIcon } from "./round-icon";
import { Title } from "./title";

const CustomLink = ({ href, children }) => {
  return (
    <a
      className={`flex flex-row justify-between font-inter text-base sm:text-22px w-full whitespace-nowrap leading-7 ${href && "hover:cursor-pointer hover:scale-105"}`}
      href={href}
    >
      <p className={`pr-10`}>{children}</p>
      <img src={arrow} alt="arrow" className={`w-16px h-16px`} />
    </a>
  );
};

const CustomLinkLayout = ({ children, className }) => {
  return (
    <div
      className={`flex flex-col w-full min-w-fit lg:w-3/12 space-y-4 ${className}`}
    >
      {children}
    </div>
  );
};

const MoreLayout = ({ children, className }) => {
  return (
    <div
      className={`flex flex-col justify-center items-center min-w-fit space-y-4 ${className}`}
    >
      {children}
    </div>
  );
};

export const UsefulLinks = ({ className }) => {
  return (
    <div
      className={`flex flex-col lg:flex-row flex-wrap justify-center items-center content-center mt-8 w-full max-w-sm lg:max-w-none px-6 gap-10 ${className}`}
    >
      <CustomLinkLayout>
        <CustomLink>Launch on DEX</CustomLink>
        <CustomLink>Lock Liquidity</CustomLink>
        <CustomLink>Make “Ackchyually..” viral</CustomLink>
        <CustomLink>Bot launch</CustomLink>
      </CustomLinkLayout>

      <CustomLinkLayout>
        <CustomLink>CEX Listings</CustomLink>
        <CustomLink>Telegram bot</CustomLink>
        <CustomLink>Discord bot</CustomLink>
        <CustomLink>Community Partnerships</CustomLink>
      </CustomLinkLayout>

      <MoreLayout>
        <RoundIcon icon={logo} />
        <Title className={`text-red-primary font-inter font-black text-lg`}>
          VALHALLA AND MORE!
        </Title>{" "}
        <Title className={`font-inter font-bold text-lg`}>
          10,000+ HODLERS
        </Title>
      </MoreLayout>
    </div>
  );
};
