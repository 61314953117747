import { useEffect } from "react";

function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

export const useWindowResizeListener = (callback) => {
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      callback();
    }, 50);

    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, [callback]);
};
