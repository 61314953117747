import "./App.css";
import {
  Background,
  Buttons,
  IconLinks,
  RoundIcon,
  Title,
  UsefulLinks,
} from "./components";
import logo from "./assets/logo.png";

const MainLayout = ({ children }) => {
  return (
    <div className="flex flex-col items-center py-6 px-2 md:p-20">
      {children}
    </div>
  );
};

function App() {
  return (
    <>
      <Background />
      <MainLayout>
        <RoundIcon isBig icon={logo} />
        <Title
          className={`font-verdana text-red-primary font-extrabold text-4xl sm:text-6xl md:text-7xl mt-8`}
        >
          ACKCHYUALLY
        </Title>
        <IconLinks />
        <Title className={`font-verdana font-extrabold text-7xl mt-74px`}>
          $ACK
        </Title>
        <Buttons />
        <Title className={`font-verdana font-extrabold text-50px mt-28`}>
          ROAD MAP
        </Title>
        <UsefulLinks />
      </MainLayout>
    </>
  );
}

export default App;
