import { useEffect, useRef, useState } from "react";
import { useWindowResizeListener } from "../hooks/useWindowResizeListener";

export const RoundIcon = ({
  className,
  icon,
  isBig,
  text,
  isClickable,
  href,
}) => {
  const [iconCenter, setIconCenter] = useState(0);
  const iconRef = useRef(null);

  const onWindowResize = () => {
    const rect = iconRef.current.getBoundingClientRect();
    setIconCenter(rect.bottom - rect.height / 2 + window.scrollY);
  };

  useEffect(() => {
    onWindowResize();
  }, []);

  useWindowResizeListener(onWindowResize);

  return (
    <>
      {isBig && (
        <div
          className={`absolute -z-10 bg-aqua-primary w-full top-0 left-0`}
          style={{ height: `${iconCenter}px` }}
        ></div>
      )}
      <a
        className={`flex flex-col ${
          isClickable && "hover:cursor-pointer hover:scale-105"
        }`}
        ref={iconRef}
        href={href}
      >
        <div
          className={`flex justify-center items-center content-center rounded-full border-4 border-black ${
            isBig ? "w-52 h-52 sm:w-60 sm:h-60 md:w-80 md:h-80" : "w-32 h-32 "
          } overflow-clip ${className || ""}`}
        >
          <img src={icon} alt="logo" className={`object-fill`} />
        </div>
        {text && (
          <p className={`text-16px align-middle text-center font-inter mt-2`}>
            {text}
          </p>
        )}
      </a>
    </>
  );
};
